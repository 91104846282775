import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";
import {
  useI18n,
  withI18n,
  sessionStorageIdentifiers,
  LocalizationNS,
  useFeatureFlag,
  useGetToken,
  isUnity,
} from "compass-commons";
// Components
import { AuthWrapper, Alert, Button } from "dms-lib";
import AppLayout from "./components/appLayout/AppLayout";
// Styles
import "./App.module.css";
import "./assets/css/global.css";
// Services
import StateService from "./services/StateService";
import UserManagerService from "./services/UserManagerService";
// Contexts
import { StateProvider } from "./contexts/StateContext";
import { StoreProvider } from "./store";
import { SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG } from "./utils/Constants";
import { ROOTPATH } from "./router/Routes";

/**
 * Remove when the move to Unity is complete
 */
const Router = isUnity ? HashRouter : BrowserRouter;

const stateService: StateService = new StateService();

function App(): JSX.Element {
  const token = (() => {
    const theToken = useGetToken();
    return isStandalone ? localStorage.getItem("token") : theToken;
  })();
  const { alertSubject } = stateService;
  const [isAllowedUser, setIsAllowedUser] = useState<boolean>();
  const { t: translate } = useI18n();

  const isLoggedInUser = Boolean(token);

  const { enabled: showErrorMessagesFeatureFlag } = useFeatureFlag(
    appConfig,
    SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG
  );
  const handleRefresh = () => {
    window.location.reload();
  };
  const refreshAction = (
    <Button size="small" color="primary" variant="text" onClick={handleRefresh}>
      Refresh
    </Button>
  );
  useEffect(() => {
    const handleReject = () => {
      if (!showErrorMessagesFeatureFlag) return;
      alertSubject.next({
        title: translate("genericErrorTitle", { ns: LocalizationNS.SHARED }),
        description: translate("genericErrorSubtitle", {
          ns: LocalizationNS.SHARED,
        }),
        action: refreshAction,
      });
    };
    window.addEventListener("unhandledrejection", handleReject);
    return () => {
      window.removeEventListener("unhandledrejection", handleReject);
    };
  }, [showErrorMessagesFeatureFlag]);

  useEffect(() => {
    const mainContextHelpKeyword = sessionStorage.getItem(
      sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD
    );

    if (mainContextHelpKeyword !== appConfig.MFE_HELP_LINK_KEYWORD) {
      sessionStorage.setItem(
        sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD,
        appConfig.MFE_HELP_LINK_KEYWORD
      );
    }
  }, []);

  useEffect(() => {
    if (!isLoggedInUser) return;

    UserManagerService.isUserAllowed().then((r) => {
      setIsAllowedUser(r);
    });
  }, [isLoggedInUser]);

  return (
    (isLoggedInUser || !!isStandalone) && (
      <React.StrictMode>
        <StoreProvider>
          <StateProvider value={stateService}>
            <Helmet defer={false}>
              <title>
                {`${translate("productNameAcronym", {
                  ns: LocalizationNS.SHARED,
                })} | ${translate("title")}`}
              </title>
            </Helmet>
            <AuthWrapper
              isAuthorized={isAllowedUser || !!isStandalone}
              isLoading={isStandalone ? false : isAllowedUser === undefined}
              unauthorizedTitle={translate("unauthorized", {
                ns: LocalizationNS.SHARED,
              })}
              unauthorizedDescription={translate("unauthorizedContact", {
                ns: LocalizationNS.SHARED,
              })}
            >
              <div className="user-manager-root" data-cr="user-manager-root">
                <Router>
                  <Routes>
                    <Route path={`${ROOTPATH}/*`} element={<AppLayout />} />
                  </Routes>
                </Router>
              </div>
              <Alert alertNotificationSubject={alertSubject} />
            </AuthWrapper>
          </StateProvider>
        </StoreProvider>
      </React.StrictMode>
    )
  );
}

export default withI18n(App);
